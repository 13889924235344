import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import firebase from "firebase/app";
import configs from "./configs";

const config = configs.betuwewind;
firebase.initializeApp(config.firebaseConfig);
const ConfigContext = React.createContext();

ReactDOM.render(
    <React.StrictMode>
        <ConfigContext.Provider value={config}>
            <App/>
        </ConfigContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export {ConfigContext};