import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Checkbox, Container, Form, Header} from "semantic-ui-react";
import "firebase/auth";
import firebase from "firebase";
import ContentEditor from "../../components/ContentEditor";
import axios from "axios";
import {ConfigContext} from "../../index";

function VoedselbosPage() {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        firebase.database().ref("voedselbos").get().then(function (snapshot) {
            if (snapshot.exists()) {
                let data = {
                    ...snapshot.val(),
                };
                data.content = Object.values(data.content || {});
                setData(data);
            }
        });

    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const cancel = (e) => {
        e.preventDefault();
        window.location.reload();
        return false;
    }

    const {fileUploadUrl} = useContext(ConfigContext);
    const save = async (e) => {
        e.preventDefault();
        setLoading(true);

        let contentToSave = [];
        for (let part of data.content) {

            if (part.type === "image" && part.uploaded === false) {

                let token = await firebase.auth().currentUser.getIdToken(true);
                const data = new FormData();
                data.append('file', part.file);
                let res = await axios.post(fileUploadUrl, data, {
                    headers: {
                        "x-file-access": "public",
                        "x-auth-token": token,
                    }
                });
                if (res.status === 200 && res.data && res.data.url) {
                    part = {
                        type: "image",
                        "url": res.data.url,
                        "image-url": res.data.url,
                        uploaded: true,
                        "image-height": res.data.height,
                        "image-width": res.data.width,
                    };
                } else {
                    alert("Something went wrong...");
                    continue;
                }
            }

            contentToSave.push(part);

        }


        firebase.database().ref("voedselbos").set({
            ...data,
            content: contentToSave,
        }).then(() => {
            window.document.location.reload();
        });
        return false;

    }

    return <>
        {data && <>

            <Form onSubmit={save} loading={loading}>
                <Form.Field>

                    <h2>Homepagina</h2>

                    <Checkbox toggle label="Visible" checked={data.show}
                        // onChange={(e, data) => updateItem("active", data.checked)}
                              style={{marginRight: 20}}/><br/><br/>

                    <strong>Titel</strong><br/>
                    <input type="text" value={data.title} name={"title"} onChange={handleChange}/><br/><br/>

                    <strong>Uitgelicht</strong><br/>
                    <input type="text" value={data.subtitle} name={"subtitle"} onChange={handleChange}/><br/><br/>

                    {/*<strong>Link</strong><br/>*/}
                    {/*<input type="text" value={data.url} name={"url"} onChange={handleChange}/><br/><br/>*/}

                </Form.Field>

                <h2>Detailpagina</h2>
                <Card fluid>
                    <Card.Content>
                        <ContentEditor path={"voedselbos"} item={data} noform onChange={content => setData(
                            {
                                ...data,
                                content: content
                            }
                        )}/>
                    </Card.Content>
                </Card>


                <Button type='submit' primary floated="right">Save</Button>
                <Button floated="right" onClick={cancel}>Cancel</Button>
            </Form>
        </>}
    </>;
}

export default VoedselbosPage;
