
export default {
    laketurkana: {
        name: 'KP&P Africa Lake Turkana',
        image: 'kpp.png',
        menu: [
            {
                type: "home",
                link: "/",
            },
            {
                type: "texts"
            },
            {
                type: "settings"
            },
            {
                type: "users"
            },
        ],
        home: [
            'message',
        ],
        settings: {
            // headerPhotos: true,
        },
        texts: {
            titles: {
                "live-availability": "Turbines online",
            }
        },
        firebaseConfig: {
            apiKey: "AIzaSyASzun0emxbcWt6i4l3bHjH3PHENf3HW7Y",
            authDomain: "live-megawatt-lake-turkana.firebaseapp.com",
            databaseURL: "https://live-megawatt-lake-turkana-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "live-megawatt-lake-turkana",
            storageBucket: "live-megawatt-lake-turkana.appspot.com",
            messagingSenderId: "89765714813",
            appId: "1:89765714813:web:5be86fbf4d97ec50bcca14"
        },
    },
    wpf: {
        name: 'Windpark Fryslân',
        image: 'wpf.png',
        menu: [
            {
                type: "home",
                link: "/",
            },
            {
                type: "texts"
            },
            {
                type: "settings"
            },
        ],
        settings: {
            headerPhotos: true,
        },
        texts: {
        },
        firebaseConfig: {
            apiKey: "AIzaSyB7b1Mxzm6yLFrmzRwQiAy2nOu6nzeOFrM",
            authDomain: "windpark-fryslan.firebaseapp.com",
            databaseURL: "https://windpark-fryslan-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "windpark-fryslan",
            storageBucket: "windpark-fryslan.appspot.com",
            messagingSenderId: "683732272016",
            appId: "1:683732272016:web:2675809e403eb7c56e2915",
        },
        fileUploadUrl: "https://wpf-back-end.livemegawatt.com/file-upload",
    },
    betuwewind: {
        fileUploadUrl: "https://betuwewind-back-end.livemegawatt.com/file-upload",
        name: 'Betuwewind',
        image: 'betuwewind.png',
        menu: [
            {
                type: "home",
                link: "/",
            },
            {
                type: "voedselbos",
                title: "Voedselbos",
            },
            {type: "texts"},
            {
                type: "faq",
                title: "FAQ",
            },
            {
                type: "questions",
                title: "Ingestuurde vragen"
            },
            {
                type: "launchscreen-news",
                title: "Uitgelicht nieuwsbericht"
            },
        ],
        settings: {

        },
        texts: {
            singleField: [
                {
                    path: "status-page-texts/ijsafzetting",
                    title: "IJsafzetting samenvatting",
                },
                {
                    path: "status-page-texts/onderhoud",
                    title: "Onderhoud samenvatting",
                },
                {
                    path: "status-page-texts/storing",
                    title: "Storing samenvatting",
                },
                {
                    path: "status-page-texts/slagschaduw",
                    title: "Slagschaduw samenvatting",
                },
            ]
        },
        firebaseConfig: {
            apiKey: "AIzaSyCZV0iwhAi6BOMWuNa6izF4cmeekQCi_gs",
            authDomain: "live-megawatt-betuwewind.firebaseapp.com",
            databaseURL: "https://live-megawatt-betuwewind-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "live-megawatt-betuwewind",
            storageBucket: "live-megawatt-betuwewind.appspot.com",
            messagingSenderId: "833369049606",
            appId: "1:833369049606:web:66acdfbdc1f2121751d187"
        },
    },
};
