import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Container, Dimmer, Form, Grid, Header, Image, Loader, Message, Segment} from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import update from "immutability-helper";
import {ConfigContext} from "../../index";

function HeaderPhotos() {

    const [items, setItems] = useState([]);
    const [uploading, setUploading] = useState(null);

    useEffect(() => {

        firebase.database().ref("settings/header-photos").get().then(function (snapshot) {
            if (snapshot.exists()) {
                let items = snapshot.val();
                setItems(items);
            }
        });

    }, []);

    const {fileUploadUrl} = useContext(ConfigContext);
    const handleFile = async (page, event) => {
        setUploading(page);
        let file = event.target.files[0];
        let token = await firebase.auth().currentUser.getIdToken(true);
        const data = new FormData();
        data.append('file', file);
        let res = await axios.post(fileUploadUrl, data, {
            headers: {
                "x-file-access": "public",
                "x-auth-token": token,
            }
        });
        if (res.status === 200 && res.data && res.data.url) {
            let url = res.data.url;
            firebase.database().ref("settings/header-photos/" + page).set(url).then(() => {
                setUploading(null);
                setItems(update(items, {
                    [page]: {$set: url}
                }))
            })
        } else {
            alert("Something went wrong...");
        }
    }

    return (
        <Segment>
            <h1>Header foto's</h1>
            {items ?
                <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <h3>Actueel</h3>
                            {uploading === "live" &&
                            <Dimmer inverted active>
                                <Loader />
                            </Dimmer>}
                            <Image src={items.live}/><br/>
                            <strong>Nieuwe afbeelding uploaden</strong><br/>
                            <input type="file" name="url" disabled={uploading !== null}
                                   onChange={e => handleFile("live", e)}/>
                        </Grid.Column>
                        <Grid.Column>
                            <h3>Historie</h3>
                            {uploading === "history" &&
                            <Dimmer inverted active>
                                <Loader />
                            </Dimmer>}
                            <Image src={items.history}/><br/>
                            <strong>Nieuwe afbeelding uploaden</strong><br/>
                            <input type="file" name="url" disabled={uploading !== null}
                                   onChange={e => handleFile("history", e)}/>
                        </Grid.Column>
                        <Grid.Column>
                            <h3>Het windpark</h3>
                            {uploading === "info" &&
                            <Dimmer inverted active>
                                <Loader />
                            </Dimmer>}
                            <Image src={items.info}/><br/>
                            <strong>Nieuwe afbeelding uploaden</strong><br/>
                            <input type="file" name="url" disabled={uploading !== null}
                                   onChange={e => handleFile("info", e)}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid> : <Loader active/>
            }

        </Segment>
    );
}

export default HeaderPhotos;
